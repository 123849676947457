import ReactMarkdown from 'react-markdown';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Tabs from '../../static/undraw_tabs_re_a2bd.svg';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const content = `#### Foods.id
Semacam blog yang berisi informasi terkait resep masakan, tempat wisata kuliner, dan artikel-artikel pendukung yang berkaitan dengan makanan. Berbentuk \`web\` yang dapat diakses melalui _desktop_ dan _mobile_.

Link: [foods.id](https://foods.id/)
`;

const usefulLinks = {
  cleanCode: `#### Naming Guides In Programming`,
  golangLint: `#### Golang Lint`,
  emailTemp: `#### Temporary Email`,
}

library.add(fas);

function MasterpiecesPage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Masterpieces Page" });
  }, [location]);

  // @ts-ignore
  const brushIcon: IconProp = "fa-solid fa-brush";

  return (
    <div className="container">
      <h2><FontAwesomeIcon icon={brushIcon} /> Masterpieces</h2>
      <p>All shared benefit information.</p>

      <h3>Portfolios</h3>
      <ol className="px-1">
        <li>
          <ReactMarkdown children={content} />
          <Link to={'/masterpieces/foodsid'}>Read more...</Link>
        </li>
      </ol>
      <hr />

      <h3>Useful Links</h3>
      <ul className="list-group list-group-flush text-center">
        <li className="list-group-item bg-so-dark">
          <ReactMarkdown children={usefulLinks.cleanCode} />
          <span>
            <a href={'https://levelup.gitconnected.com/a-guide-for-naming-things-in-programming-2dc2d74879f8/'} rel='noreferrer' target='_blank'>https://levelup.gitconnected.com/a-guide-for-naming-things-in-programming-2dc2d74879f8/</a>
          </span>
        </li>
        <li className="list-group-item bg-so-dark">
          <ReactMarkdown children={usefulLinks.golangLint} />
          <span>
            <a href={'https://golangci-lint.run/'} rel='noreferrer' target='_blank'>https://golangci-lint.run/</a>
          </span>
        </li>
        <li className="list-group-item bg-so-dark">
          <ReactMarkdown children={usefulLinks.emailTemp} />
          <span>
            <a href={'https://temp-mail.org/en/'} rel='noreferrer' target='_blank'>https://temp-mail.org/en/</a>
          </span>
        </li>

        <hr />
        <li className="list-group-item bg-so-dark">
          <Link to={'/masterpieces/useful-links'}>Read more...</Link>
        </li>
      </ul>

      <img src={Tabs} className="position-absolute bottom-0 start-0 d-none d-md-block" style={{ marginLeft: '25px', width: '18%', opacity: '80%', zIndex: -1 }} alt="tabs-img"/>

      <hr />
    </div>
  );
}

export default MasterpiecesPage;
