import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithubSquare, faYoutubeSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

interface SocialFooter {
  title: string,
  link: string,
  icon: any
}

const data: SocialFooter[] = [
  {
    title: "GitHub",
    link: "https://github.com/mohammadanang",
    icon: <FontAwesomeIcon icon={faGithubSquare} size="lg" />
  },
  {
    title: "YouTube",
    link: "https://www.youtube.com/channel/UCKJx6n1PIpMrjx0zYQhDYRQ",
    icon: <FontAwesomeIcon icon={faYoutubeSquare} size="lg" />
  },
  {
    title: "Linkedin",
    link: "https://www.linkedin.com/in/anangm182/",
    icon: <FontAwesomeIcon icon={faLinkedin} size="lg" />
  }
];

function Footer() {
  return (
    <div id="footer" className="mt-4 mb-4">
      <hr />
      <div className="container text-center text-white mb-2">
        {data.map((val, i) => {
          return (
            <a href={val.link} rel="noreferrer" key={i} className="p-1" target="_blank" title={val.title}>
              {val.icon}
            </a>
          );
        })}
      </div>
      <div className="container text-center">
          &copy; Copyright. { new Date().getFullYear() } |&nbsp;
          <Link to="/">
              <small>by mohammad.anang</small>
          </Link>      
      </div>
  </div>
  );
}

export default Footer;
