import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import BackButton from '../../components/general/BackButton';

function Foodsid() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Foodsid Page" });
  }, [location]);

  return (
    <div className="container">
      <BackButton url={'/masterpieces'} />

      <h2>Foodsid</h2>
      <p>...</p>
      <hr />
    </div>
  );
}

export default Foodsid;
