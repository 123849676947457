import Coding from '../static/coding_undraw.svg';
import '../styles/css/home.css';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const textPink = {
  color: '#f92672'
};
const textGreen = {
  color: '#a6e22e'
};
const holopinBadge = 'https://holopin.me/anangm182';

library.add(fas);

function HomePage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Home Page" });
  }, [location]);

  // @ts-ignore
  const earthAsiaIcon: IconProp = "fa-solid fa-earth-asia";

  return (
    <div className="container">
      <h2><FontAwesomeIcon icon={earthAsiaIcon} /> To Whom It May Concern</h2>
      <p>
        Hello! My name is <code>Mohammad Anang</code>.<br />
        Typing is my daily activities. I can&rsquo;t live without typing.<br />
        Sometimes thinking. Sometimes sleeping.<br />
        Eyes up and down. Head left and right.<br />
        No matter how tired I am. I will always typing.<br />
        That&rsquo;s what people called a <code>Software Engineer</code>.
      </p>

      <hr />

      <h3>Daily life cycle</h3>
      <ol className="px-1">
        <li>Wake Up 🌅.</li>
        <li>Eating 🍜.</li>
        <li>Typing ⌨️.</li>
        <li>Sleeping 🌙.</li>
      </ol>

      <hr />
      
      <h3>Quote of the day</h3>
      <div className="position-relative">
        <img src={Coding} className="position-absolute bottom-0 end-0 me-sm-4 me-1" style={{ width: '28%', opacity: '85%' }} alt="coding"/>
      </div>

      <div className="highlight">
        <pre className="quote-style">
          <code className="language-javascript" data-lang="javascript">
            <span style={textPink}>&gt;</span>&nbsp;
            <span style={textGreen}>Remember</span>&nbsp;
            <span style={textGreen}>to</span>&nbsp;
            <span style={textGreen}>eat</span>,&nbsp;
            <span style={textGreen}>sleep</span>&nbsp;
            <span style={textPink}>&amp;</span>&nbsp;
            <span style={textGreen}>blink</span>&nbsp;
            <span style={textPink}>!</span>
          </code>
        </pre>
      </div>

      <div className="text-center mt-5">
        <img src={holopinBadge} className="px-5" alt="holopin badge" />
      </div>
    </div>
  );
}

export default HomePage;
