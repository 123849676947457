import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4'

import Main from './layouts/Main';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/css/researcher.css';

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

// Initialize React Ga with your tracking ID
const googleAnalyticsId = String(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
ReactGA.initialize(googleAnalyticsId);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  </React.StrictMode>,
);
