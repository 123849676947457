import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PostImg from '../static/undraw_undraw_posts_givd_-1-_5vi7.svg';

library.add(far);

function PostsPage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Posts Page" });
  }, [location]);

  // @ts-ignore
  const newspaperIcon: IconProp = "fa-regular fa-newspaper";

  return (
    <div className="container">
      <h2><FontAwesomeIcon icon={newspaperIcon} /> My Posts</h2>
      <ol className="px-1">
        <li>
          <strong>DEV</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;Bagaimana cara menggunakan MongoDB tanpa melakukan instalasi?&quot;</em> | <b>Oct 15, 2022</b><br />
          Pernahkah merasa ribet saat dipaksa beradaptasi dengan integrasi baru di suatu aplikasi atau projek? Misalnya penerapan database jenis baru yang menggunakan mongodb. Mungkin kebanyakan developer pemula atau junior developer yang sering meresakannya. Penyebabnya ... <a href="https://dev.to/mohammadanang/mongo-docker-3394/" target="_blank" rel="noreferrer">Read more</a>
        </li>
        <li>
          <strong>DEV</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;Bagaimana melakukan push Docker image ke private container registry secara gratis?&quot;</em> | <b>Feb 29, 2024</b><br />
          Penggunaan container service pada saat proses deployment untuk kebutuhan production menjadi ketergantungan yang lumayan tinggi. Sejalan dengan kepentingan tersebut, container registry menjadi gerbang awal sebelum menjalankan sebuah container. Dia digunakan untuk ... <a href="https://dev.to/mohammadanang/gitlab-container-registry-202g/" target="_blank" rel="noreferrer">Read more</a>
        </li>
        <li>
          <strong>DEV</strong><sup id="fnref:1"><a href="#fn:1" className="footnote-ref" role="doc-noteref">1</a></sup>, <em>&ldquo;Bagaimana cara menggunakan PostgreSQL tanpa melakukan instalasi?&quot;</em> | <b>Mar 7, 2024</b><br />
          Salah satu database SQL yang cukup populer saat ini adalah PostgreSQL. Didukung perkembangan bahasa pemrograman yang cukup tinggi, baik ragam dan jenisnya, penggunaan berbagai macam database mendapatkan pengaruh juga. Hal ini menuntut para software developer untuk ... <a href="https://dev.to/mohammadanang/bagaimana-cara-menggunakan-postgresql-tanpa-melakukan-instalasi-2hi8/" target="_blank" rel="noreferrer">Read more</a>
        </li>
      </ol>
      <hr />

      <br />
      <h2>References</h2>
      <ul>
        <li>
          <a href="https://dev.to" target="_blank" rel="noreferrer">DEV</a>.
        </li>
      </ul>
      <hr />

      <section className="footnotes" role="doc-endnotes">
        <br />

        <ol>
          <li id="fn:1" role="doc-endnote">
            <p><a href="https://dev.to/mohammadanang" target="_blank" rel="noreferrer">DEV</a>.&#160;<a href="#fnref:1" className="footnote-backref" role="doc-backlink">&#x21a9;&#xfe0e;</a></p>
          </li>
        </ol>
      </section>

      <div className="position-relative">
        <img src={PostImg} className="position-absolute bottom-0 end-0" style={{ width: '23%', opacity: '90%' }} alt="posts"/>
      </div>
    </div>
  );
}

export default PostsPage;
