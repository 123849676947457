import { Link } from 'react-router-dom';

interface BackBtn {
  url: string
}

function BackButton(props: BackBtn) {
  return (
    <>
      <Link to={props.url}>⬅️ Back</Link>
    </>
  );
}

export default BackButton;
