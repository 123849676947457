import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Messages from '../static/messages_undraw.svg';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

library.add(fas);

function ContactPage() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Contact Page" });
  }, [location]);

  // @ts-ignore
  const envelopeIcon: IconProp = "fa-solid fa-envelope";
  // @ts-ignore
  const locationDotIcon: IconProp = "fa-solid fa-location-dot";
  // @ts-ignore
  const mobileRetroIcon: IconProp = "fa-solid fa-mobile-retro";

  return (
    <div className="container">
      <h2><FontAwesomeIcon icon={mobileRetroIcon} /> Contact Me</h2>
      <p>Feel free to drop me some message for business inquiries.</p>

      <ul className='mt-3 px-1'>
        <li>
          <FontAwesomeIcon icon={envelopeIcon} className='me-2' />
          Email: <a href="mailto:info@anangm182.com">info@anangm182.com</a>
        </li>
      </ul>
      <hr />

      <h3>Stay At <FontAwesomeIcon icon={locationDotIcon} /></h3>
      <blockquote>
        <p>Jakarta, Indonesia</p>
      </blockquote>
      <hr />

      <h3>Social</h3>
      <ol className="px-2">
        <li><a href="https://twitter.com/anangm182">Twitter/X</a></li>
        <li><a href="https://www.instagram.com/anangm182dev/">Instagram</a></li>
        <li><a href="https://www.youtube.com/channel/UCKJx6n1PIpMrjx0zYQhDYRQ">Youtube</a></li>
        <li><a href="https://www.linkedin.com/in/anangm182/">Linkedin</a></li>
      </ol>

      <div className="position-relative">
        <img src={Messages} className="position-absolute bottom-0 end-0" style={{ width: '30%', opacity: '85%' }} alt="messages"/>
      </div>
      <hr />
    </div>
  );
}

export default ContactPage;
