import HomePage from '../pages/Home';
import AboutPage from '../pages/About';
import MasterpiecesPage from '../pages/masterpieces/Index';
import FoodsidPage from '../pages/masterpieces/Foodsid';
import UsefulLinksPage from '../pages/masterpieces/UsefulLinks';
import PostsPage from '../pages/Posts';
import ContactPage from '../pages/Contact';
import Layout from './Layout';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from '../pages/NotFound';

function Main() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/masterpieces" element={<MasterpiecesPage />} />
        <Route path="/masterpieces/foodsid" element={<FoodsidPage />} />
        <Route path="/masterpieces/useful-links" element={<UsefulLinksPage />} />
        <Route path="/posts" element={<PostsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
  );
}

export default Main;
