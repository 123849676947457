import { Link } from 'react-router-dom';

const textJumbo = {
  fontSize: '90px',
  fontWeight: 600,
  marginTop: '100px',
  marginBottom: '8px'
};
const textSmall = {
  fontWeight: 200,
  fontSize: '13px',
  marginBottom: 0
};
const homeLink = {
  fontSize: '15px'
};
const breakingLine = {
  marginTop: '4px',
  marginBottom: '100px'
};

function NotFoundPage() {
  return (
    <div className="container">
      <h1 className="text-center" style={textJumbo}>4😕4</h1>
      <p className="text-center" style={textSmall}>You're lost !!</p>
      <div className="text-center" style={breakingLine}>
        <Link to="/">
          <span style={homeLink}>Back to home 🏠</span>
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
