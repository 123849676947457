import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import BackButton from '../../components/general/BackButton';
import Preferences from '../../static/undraw_preferences_re_49in.svg';

function UsefulLinks() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Useful Links Page" });
  }, [location]);

  return (
    <div className="container">
      <BackButton url={'/masterpieces'} />

      <h2>Useful Links</h2>
      <ol>
        <li>
          <strong>PROJECT</strong>
          <p>There's many links about project development.</p>

          <ul>
            <li>
              <a href={'https://goodbrief.io/'} rel='noreferrer' target='_blank'>https://goodbrief.io/</a>
            </li>
          </ul>
        </li>
        <br />
        <li>
          <strong>BACKEND</strong>
          <p>There's many links about backend development.</p>

          <ul>
            <li>
              <a href={'https://roadmap.sh/backend/'} rel='noreferrer' target='_blank'>https://roadmap.sh/backend/</a>
            </li>
          </ul>
        </li>
        <br />
        <li>
          <strong>TOOLS</strong>
          <p>There's many links about tools development.</p>

          <ul>
            <li>
              <a href={'https://etcher.balena.io/'} rel='noreferrer' target='_blank'>https://etcher.balena.io/</a>
            </li>
          </ul>
        </li>
        <br />
        <li>
          <strong>EMAIL</strong>
          <p>There's many links about email development.</p>

          <ul>
            <li>
              <a href={'https://proton.me/mail/'} rel='noreferrer' target='_blank'>https://proton.me/mail/</a>
            </li>
            <li>
              <a href={'https://temp-mail.org/en/'} rel='noreferrer' target='_blank'>https://temp-mail.org/en/</a>
            </li>
          </ul>
        </li>
        <br />
        <li>
          <strong>CLEAN CODE</strong>
          <p>There's many links about clean code.</p>

          <ul>
            <li>
              <a href={'https://levelup.gitconnected.com/a-guide-for-naming-things-in-programming-2dc2d74879f8/'} rel='noreferrer' target='_blank'>https://levelup.gitconnected.com/a-guide-for-naming-things-in-programming-2dc2d74879f8/</a>
            </li>
          </ul>
        </li>
        <br />
      </ol>

      <img src={Preferences} className="position-absolute top-0 start-0 d-none d-md-block" style={{ marginTop: '70px', marginLeft: '160px', width: '16%', opacity: '80%', zIndex: -1 }} alt="preferences-img"/>

      <hr />
    </div>
  );
}

export default UsefulLinks;
